<template>
  <div id="inspire">
    <div>
      <div v-if="!showData" class="flex min-h-screen h-auto items-center justify-center">
        <div class="circleLoader ease-linear mr-2"></div>
      </div>
      <div class="flex min-h-screen h-auto" :class="mobileView ? 'px-1 py-4' : 'p-4'" v-if="showData">
        <div  class=" bg-white card rounded-2xl  w-full px-4 py-3 sm:m-0 ">
          <div v-if="showFreePlanActiveMsg || showFreePlanExpireMsgg" class=" flex justify-center" :class="`${showFreePlanActiveMsg ? 'text-primary' : showFreePlanExpireMsgg ? 'text-error' : ''}`">
            <span :class="`${showFreePlanActiveMsg ? 'border-primary' : showFreePlanExpireMsgg ? 'border-error' : ''} ${mobileView ? 'p-1.5 px-3 heading-6' : 'p-2 px-5 heading-5'}`" class="border border-opacity-40  rounded-lg">
              {{showFreePlanActiveMsg ? 'You are currently using Free plan, please consider upgrading to enjoy uninterrupted services' : showFreePlanExpireMsgg ? 'Your Free Trial has expired, please upgrade your plan to enjoy uninterrupted services' : ''}}</span></div>
          <div class="text-center py-6">
            <p :class="mobileView ? 'text-xl' : 'text-4xl'" class=" text-text1 font-serif">Browse Plans</p>
          </div>
          <!-- <div class="text-center py-4">
            <p :class="mobileView ? 'text-xl' : 'text-4xl'" class="font-semibold">Try ZizbeyJobs Now. Start your free trial today.</p>
            <p :class="mobileView ? 'heading-3 text-text1' : 'text-2xl'" class="font-semibold mt-2">Save upto $100/mo</p>
          </div> -->
          <div class="justify-center flex mb-4" v-if="false">
             <div class="select_button heading-5 text-gray3">
              <Dropdown 
              :inputext="selectedText" 
              :inputId="'wefswefwefe'"
              :lableText="'Select Currency'"
              :placholderText="``"
              :autoFocus="false"
              :showPlus="false"
              :showArrow="true"
              :setReadOnly="false"
              :keyName="'showValue'"
              :listId="'currencyMasterId'"
              :items="currenctyList"
              @selectNewForVal="slectRadius"
              />
            </div>
          </div>
          <div class="justify-center flex pb-2">
            <div class="flex items-center ">
              <p  class="" :class="`${isMonthlyCheck ? 'text-text1 ' : 'text-gray3'} ${mobileView ? 'text-lg' : 'text-2xl'}`">Monthly</p>
              <label class="cursor-pointer mr-3" :class="`${isMonthlyCheck ? 'ml-4' : 'ml-3'} ${mobileView ? 'cu3_switch' : 'cu2_switch'}`">
                <input type="checkbox" v-model="isMonthlyCheck2" @click="changeButtpn()">
                <span class="" :class="mobileView ? 'cu3_slider cu3_round' : 'cu2_slider cu2_round'"></span>
              </label>
              <p :class="`${!isMonthlyCheck ? 'text-text1 ' : 'text-gray3'} ${mobileView ? 'text-lg' : 'text-2xl'}`">Annual</p>
            </div>
          </div>
          <div class="flex justify-center mt-6  grid-cols-3 2xl:px-24 xl:4 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 " v-if="planDetailArray.length > 0 && !mobileView">
            <div v-for="(data, index) in planDetailArray" :key="index" class=" m-4 card rounded-2xl bg-white relative  justify-center text-center h-full" style="max-width: 430px !important;min-width: 320px !important;" :class="trendingPlanId === data.planId ? 'border-primary border-2' : 'border-gray2 border-2'" >
              <div class="absolute -top-5 bg-secondary text-white p-2 rounded-lg csdcdc w-28 h-8 flex items-center justify-center" v-if="trendingPlanId === data.planId">
                <div class="heading-6">Most Trending</div>
              </div>
              <div class="text-center justify-center p-4 ">
                <div class="flex items-center justify-center">
                  <div><img height="50px" width="50px" src="@/assets/images/svg/Person-with-Bag.svg" alt=""></div>
                  <div class="text-3xl font-semibold text-text1">{{ data.planName }}</div>
                </div>
              </div>
              <div class="text-gray4 heading-4 my-2 px-4 overFlowParaA" v-if="data.shortTitle !== ''">{{ data.shortTitle }}</div>
              <div class="text-3xl font-semibold text-text1 my-4 mb-0" v-if="isMonthlyCheck">{{ data.monthFrequncy.paymentTitle }}</div>
              <div class="text-3xl font-semibold text-text1 my-4 mb-0" v-if="!isMonthlyCheck">{{ data.yearFrequncy.paymentTitle }}</div>
              <div class="text-xl font-semibold text-secondary mt-2">{{isMonthlyCheck ? data.monthFrequncy.saveAmountText : data.yearFrequncy.saveAmountText}}</div>
              <!-- <div class="text-xl font-semibold text-secondary mt-2" v-if="!isMonthlyCheck">You Save {{isMonthlyCheck ? data.monthFrequncy.symbol : data.yearFrequncy.symbol }}{{((data.monthPricing * 12) - data.yearPricing) | amountFormater}} {{isMonthlyCheck ? 'monthly' : 'annually'}}</div> -->
              <div class="pt-4 flex justify-center">
                <Button v-if="currentActivePlan !== data.planId"
                  :btnSize="'planChip'"
                  :textColor="'white'"
                  :btnColor="'primary'"
                  :btnText="`Subscribe Now`"
                  @buttonAction="redirectToSubscriptionScreen(data)"
                />
                <button type="button" class="bg-success text-white py-3 px-6 cta-text font-semibold shadow  hover:shadow-xl global_btn rounded-xl cursor-default" v-if="currentActivePlan === data.planId">
                  <i class="fas fa-check pr-1"></i>Current Subscription
                </button>
              </div>
              <div class="text-xl font-semibold text-gray4 my-6">
                <div class="flex items-center justify-center">
                  <div class="pr-1"><img height="20px" width="20px" src="@/assets/images/svg/user-plan.svg" alt=""></div>
                  <span v-if="data.maxUser <= 1 ">1 User</span>
                  <span v-else>1-{{data.maxUser}} Users</span>
                </div>
              </div>
              <div class="min-h-auto bg-gray1 rounded-2xl text-left" v-if="data.shortInfo !== ''">
                <div class="p-4 px-2 heading-4 text-gray4 ql-editor ql-editor-overflow-inherit" v-html="data.shortInfo"></div>
                <!-- <div class="p-4 px-2 heading-3 text-gray4">○ {{data.planName}} Plan includes:</div>
                <div class="p-3 pt-0 heading-4 text-gray4">
                  <li>Schedule and Manage Jobs with single visits</li>
                  <li>Monitor Employee Shifts</li>
                  <li>Customer Management</li>
                  <li>Item & Tax Management</li>
                  <li>Create Quotes and invoices</li>
                </div> -->
              </div>
              <div v-if="allListPlanMaximumUserValue === data.maxUser && isAlreadyMaxPlanActive">
                <div class="text-gray4 heading-4 my-2 px-4">Need more Users? please contact us by going to Main app Menu and click support with your requirements</div>
              </div>
            </div>
          </div>
          <div class="" v-if="planDetailArray.length > 0 && mobileView">
            <div v-for="(data, index) in planDetailArray" :key="index" class=" card rounded-2xl bg-white relative  justify-center text-center" :class="`${trendingPlanId === data.planId ? 'border-primary border-2 my-5 mt-7' : 'border-gray2 border-2 mt-4'} ${mobileView ? 'm-1 mx-0' : 'm-4'}`" >
              <div class="absolute -top-5 bg-secondary text-white p-2 rounded-lg csdcdc w-28 h-8 flex items-center justify-center" v-if="trendingPlanId === data.planId">
                <div class="heading-6">Most Trending</div>
              </div>
              <div class="text-center justify-center p-4">
                <div class="flex items-center justify-center">
                  <div><img height="50px" width="50px" src="@/assets/images/svg/Person-with-Bag.svg" alt=""></div>
                  <div class="text-2xl font-semibold text-text1">{{ data.planName }}</div>
                </div>
              </div>
              <!-- <div class="absolute right-1 top-1" v-if="data.isOpen">
                <div @click="data.isOpen = !data.isOpen" class="text-white bg-primary rounded-full px-3 py-1.5 cursor-pointer"><i class="fa-solid fa-caret-up"></i></div>
              </div> -->
              <!-- <div class="absolute right-1 top-2" v-if="!data.isOpen">
                <span @click="data.isOpen = !data.isOpen" class="text-white bg-primary rounded-full px-3 py-2 cursor-pointer"><i class="fa-solid fa-caret-down"></i></span>
              </div> -->
              <div class="text-gray4 heading-4 my-2 px-4 " v-if="data.shortTitle !== ''">{{ data.shortTitle }}</div>
              <div class="text-2xl font-semibold text-text1 my-3 mb-0" v-if="isMonthlyCheck">{{ data.monthFrequncy.paymentTitle }}</div>
              <div class="text-2xl font-semibold text-text1 my-3 mb-0" v-if="!isMonthlyCheck">{{ data.yearFrequncy.paymentTitle }}</div>
              <div class="text-xl font-semibold text-secondary mt-2" >{{isMonthlyCheck ? data.monthFrequncy.saveAmountText : data.yearFrequncy.saveAmountText}}</div>
              <!-- <div class="text-lg font-semibold text-secondary mt-2" v-if="!isMonthlyCheck">You Save {{isMonthlyCheck ? data.monthFrequncy.symbol : data.yearFrequncy.symbol }}{{((data.monthPricing * 12) - data.yearPricing) | amountFormater}} {{isMonthlyCheck ? 'monthly' : 'annually'}}</div> -->
              <div class="pt-4 flex justify-center">
                <Button v-if="currentActivePlan !== data.planId"
                  :btnSize="'planChip'"
                  :textColor="'white'"
                  :btnColor="'primary'"
                  :btnText="`Subscribe Now`"
                  @buttonAction="redirectToSubscriptionScreen(data)"
                />
                <button :class="isSmallScreen ? 'py-2 px-3 heading-7' : 'py-3 px-6 cta-text font-semibold'" type="button" class="bg-success text-white  shadow  hover:shadow-xl global_btn rounded-xl cursor-default" v-if="currentActivePlan === data.planId">
                  <i class="fas fa-check pr-1"></i>Current Subscription
                </button>
              </div>
              <div v-show="mobileView" class="text-lg font-semibold text-gray4 my-4">
                <div class="flex items-center justify-center">
                  <div class="pr-1"><img height="20px" width="20px" src="@/assets/images/svg/user-plan.svg" alt=""></div>
                  <span v-if="data.maxUser <= 1 ">1 User</span>
                  <span v-else>1-{{data.maxUser}} Users</span>
                </div>
              </div>
              <div class="min-h-auto bg-gray1 rounded-2xl text-left transition " v-if="data.isOpen && data.shortInfo !== ''">
                <div class="p-4 px-2 heading-4 text-gray4 ql-editor ql-editor-overflow-inherit" v-html="data.shortInfo"></div>
                <!-- <div class="p-4 px-2 heading-3 text-gray4">○ {{data.planName}} Plan includes:</div>
                <div class="p-3 pt-0 heading-4 text-gray4">
                  <li>Schedule and Manage Jobs with single visits</li>
                  <li>Monitor Employee Shifts</li>
                  <li>Customer Management</li>
                  <li>Item & Tax Management</li>
                  <li>Create Quotes and invoices</li>
                </div> -->
              </div>
              <div v-if="allListPlanMaximumUserValue === data.maxUser && isAlreadyMaxPlanActive">
                <div class="text-gray4 heading-4 my-2 px-4">Need more Users? please contact us by going to Main app Menu and click support with your requirements</div>
              </div>
              <!-- <div class="h-10 bg-primary rounded-t-none rounded-xl items-center justify-center flex">
                <div @click="data.isOpen = !data.isOpen" v-if="data.isOpen" class="text-primary bg-white rounded-full px-3 py-1"><i class="fa-solid fa-arrow-up"></i></div>
                <span @click="data.isOpen = !data.isOpen" v-if="!data.isOpen" class="text-primary bg-white rounded-full px-3 py-1"><i class="fa-solid fa-arrow-down"></i></span>
              </div> -->
            </div>
          </div>
          <div class="flex justify-center mt-6" v-if="planDetailArray.length === 0">
            <div class=" m-4 p-4 card rounded-2xl bg-white grid border  relative  justify-center text-center border-error">
              <div class=" text-error" :class="mobileView ? 'text-4xl' : 'text-5xl'">
                <i class="fa-solid fa-triangle-exclamation fa-beat-fade"></i>
              </div>
              <div class="  text-gray4" :class="mobileView ? 'heading-3 py-3' : 'heading-2 py-6 pb-3'">
                There is no selected currency plan
              </div>
            </div>
          </div>
          <!-- <div class="mt-4" v-for="(data, index) in plans" :key="index">
            <p>{{ data.title }}</p>
            <div class="">
              <Button
                :btnSize="'medium'"
                :textColor="'white'"
                :btnColor="'success'"
                :btnText="`Subscribe to ${data.monthly.title}`"
                @buttonAction="redirectToSubscriptionScreen(data.monthly)"
              />
            </div>
            <div class="mt-2">
              <Button
                :btnSize="'medium'"
                :textColor="'white'"
                :btnColor="'success'"
                :btnText="`Subscribe to ${data.yearly.title}`"
                @buttonAction="redirectToSubscriptionScreen(data.yearly)"
              />
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="openClickPopup" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-semibold">
          <p class="heading-3 text-text1">Can't continue with selected plan</p>
        </div>
        <div  class="mb-5 font-semibold">
          <div class="heading-5 text-text2 mb-4">
            <div>You have selected 
              <span>{{userSelectedDataObject.planName}}</span> Plan which allows maximum of 
              <span>{{userSelectedDataObject.maxUser}}</span> <span>{{userSelectedDataObject.maxUser > 1 ? 'users': 'user'}}</span> and
              <span>{{userSelectedDataObject.maxStorage}} GB</span>
              <span>, however you have {{currentPlanArray.totalActiveUser > 1 ? currentPlanArray.totalActiveUser + ' active users' : currentPlanArray.totalActiveUser + ' active user'}} and </span>
              <span>{{currentPlanArray.totalUsedStorage}} </span> GB storage utilized in your account,
              <span v-if="currentPlanArray.totalActiveUser > userSelectedDataObject.maxUser"> you will have to deactivate {{currentPlanArray.totalActiveUser - userSelectedDataObject.maxUser}} {{currentPlanArray.totalActiveUser - userSelectedDataObject.maxUser > 1 ? 'users' : 'user'}}</span>
              <span v-if="currentPlanArray.totalActiveUser > userSelectedDataObject.maxUser"> and </span>
              <span v-if="userSelectedDataObject.maxStorage < currentPlanArray.totalUsedStorage"> will have to clear the storage to bring it under {{userSelectedDataObject.maxStorage}} GB</span>
              <span> to continue or you will have to </span>
              <span v-if="isAvailableMaxUserMorePlan"> select a higher plan</span>
              <span v-if="(allListPlanMaximumUserValue === this.userSelectedDataObject.maxUser) || isAlreadyMaxPlanActive">contact us by going to Main app Menu and click support with your requirements</span>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <button @click="closePopup()" class="card bg-primary text-white px-5 py-3 text-center rounded-lg hover:opacity-80 w-96 font-semibold">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from '@/View/components/dropdown.vue'
import MyJobApp from "@/api/MyJobApp.js";
import Button from "@/View/components/globalButton.vue";
export default {
  components: {
    Dropdown,
    Button,
  },
  data() {
    return {
      trendingPlanId: 0,
      allListPlanMaximumUserValue: 0,
      isAlreadyMaxPlanActive: false,
      isAvailableMaxUserMorePlan: false,
      openClickPopup: false,
      displayErrorMessage: '',
      userSelectedDataObject: null,
      diffDays: 0,
      currentPlanArray: null,
      showFreePlanActiveMsg: false,
      showFreePlanExpireMsgg: false,
      isShowDropdown: false,
      selcurrencyMasterId: 0,
      selectedText: '',
      isMonthlyCheck: false,
      isMonthlyCheck2: true,
      showData: false,
      currenctyList: [],
      planDetailArray: [],
      previusArray: [],
      mobileView: false,
      isSmallScreen: false,
      currentActivePlan: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    if (this.$route.query.currentPlanId) {
      this.currentActivePlan = parseInt(this.$route.query.currentPlanId)
    }
    if (this.$route.query.currencyCode) {
      this.getCurrList(this.$route.query.currencyCode)
    } else {
      this.getCurrList('')
    }
    this.resizeWindowHandler()
  },
  methods: {
    changeButtpn () {
      this.isMonthlyCheck = this.isMonthlyCheck2 
      this.getPlanListing()
    },
    slectRadius (data) {
      console.log('data', data)
      this.selectedText = data.showValue
      this.selcurrencyMasterId = data.currencyMasterId
      this.getPlanListing()
    },
    getPlanListing() {
      this.previusArray = []
      this.previusArray = this.planDetailArray
      MyJobApp.GetPlanListing(
        this.selcurrencyMasterId,
        this.isMonthlyCheck ? 'm' : 'y',
        (response) => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].isOpen = false
            // temp[index].monthPricing = temp[index].monthFrequncy.paymentTitle.replace(/\D/g, '')
            // temp[index].yearPricing = temp[index].yearFrequncy.paymentTitle.replace(/\D/g, '')
          }
          this.trendingPlanId = response.Data.trendingPlanId
          this.planDetailArray = temp
          setTimeout(() => {
            this.showData = true
            this.currentPlanDetail()
          }, 200);
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        }
      );
    },
    getCurrList(value) {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      MyJobApp.GetPlanCurrencyList(
        false,
        (response) => {
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].showValue = (temp[index].symbol) + ' - ' + temp[index].countryCode
          }
          this.currenctyList = temp
          if (value.toLowerCase() === 'inr' || value.toLowerCase() === 'in') {
            this.selcurrencyMasterId = 2
          } else {
            this.selcurrencyMasterId = 1
          }
          this.getPlanListing()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    // getCurrList(value) {
    //   this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
    //   MyJobApp.GetPlanCurrencyList(
    //     false,
    //     (response) => {
    //       let temp = response.Data !== null ? response.Data : []
    //       for (let index = 0; index < temp.length; index++) {
    //         temp[index].showValue = (temp[index].symbol) + ' - ' + temp[index].countryCode
    //       }
    //       this.currenctyList = temp
    //       if (value !== '') {
    //         this.currenctyList.forEach(element => {
    //           console.log('element', element.countryCode, value.toLowerCase())
    //           if (element.countryCode.toLowerCase() === value.toLowerCase()) {
    //             this.selectedText = element.showValue
    //             this.selcurrencyMasterId = element.currencyMasterId
    //           }
    //         })
    //       } else {
    //         this.selectedText = '$ - USD'
    //         this.selcurrencyMasterId = 1
    //       }
    //       this.getPlanListing()
    //       this.$store.dispatch("SetLoader", { status: false, message: "" });
    //     },
    //     (err) => {
    //       this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
    //       this.$store.dispatch("SetLoader", { status: false, message: "" });
    //     }
    //   );
    // },
    redirectToSubscriptionScreen(planData) {
      console.log("planData", planData);
      this.userSelectedDataObject = planData
      if (!this.currentPlanArray.isFree && planData.isPopupVisible) {
        this.isAvailableMaxUserMorePlan = false
        for (let index = 0; index < this.planDetailArray.length; index++) {
          console.log('Yeah that is Small users', this.planDetailArray[index], this.userSelectedDataObject.maxUser)
          if (this.planDetailArray[index].maxUser > this.userSelectedDataObject.maxUser) {
            if (this.planDetailArray[index].planId !== this.currentActivePlan) {
              this.isAvailableMaxUserMorePlan = true
            } else {
              this.isAvailableMaxUserMorePlan = false
            }
          }
        }
        let compareToArray = this.userSelectedDataObject
        // if (this.userSelectedDataObject.maxUser < this.currentPlanArray.currentPlanMaxUsersCapacity) {
          // compareToArray = this.userSelectedDataObject
        // }
        console.log('compareToArray', compareToArray)
        if (compareToArray !== '') {
          // this.displayErrorMessage = true
          // `<div>You have selected <span class="font-bold ">${this.userSelectedDataObject.planName}</span> Plan which allows maximum of <span class="font-bold">${this.userSelectedDataObject.maxUser}</span> <span>${this.userSelectedDataObject.maxUser > 1 ? 'users': 'user'}</span> and <span>${this.userSelectedDataObject.maxStorage} GB<span></div>`
          this.openClickPopup = true
        }
        // let res = this.planDetailArray.filter(a => {
        //   return a.planId === this.currentActivePlan
        // })
      } else {
        if (this.isMonthlyCheck) {
          window.open(
            `${planData.monthFrequncy.tpartyPaymentLink}?utm_source=zizbeyjobs&utm_content=${planData.monthFrequncy.tpartyPaymentId}`,
            "_blank"
          );
        } else {
          window.open(
            `${planData.yearFrequncy.tpartyPaymentLink}?utm_source=zizbeyjobs&utm_content=${planData.yearFrequncy.tpartyPaymentId}`,
            "_blank"
          );
        }
      }
    },
    closePopup () {
      this.openClickPopup = false
      this.userSelectedDataObject = {}
    },
    currentPlanDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetCurrentPlanDetail(
        response => {
          this.currentPlanArray = response.Data !== null ? response.Data : null
          const requestDt = new Date(this.currentPlanArray.currentPlanEndOn).setHours(0, 0, 0)
          const todayDate = new Date().setHours(0, 0, 0)
          console.log('requestDt--->()', requestDt, this.currentPlanArray.currentPlanEndOn)
          console.log('todayDate--->()', todayDate, new Date())
          const diffTime = Math.abs(todayDate - requestDt);
          this.diffDays = Math.ceil(parseFloat((diffTime / (1000 * 3600 * 24)).toFixed(2)));
          console.log('diffDays', this.diffDays)
          this.currentActivePlan = this.currentPlanArray.planId
          this.currentPlanArray.totalUsedStorage = (this.currentPlanArray.totalUsedStorage / (1000 * 1000 * 1000)).toFixed(0);
          if (this.currentPlanArray.isFree) {
            if (this.diffDays > 0) {
              this.showFreePlanActiveMsg = true
              this.showFreePlanExpireMsgg = false
            }
             if (requestDt < todayDate) { 
              this.showFreePlanActiveMsg = false
              this.showFreePlanExpireMsgg = true
            }
          } else {
            this.allListPlanMaximumUserValue = Math.max(...this.planDetailArray.map(o => o.maxUser))
            console.log('this.allListPlanMaximumUserValue', this.allListPlanMaximumUserValue)
            if (this.allListPlanMaximumUserValue === this.currentPlanArray.currentPlanMaxUsersCapacity) {
              this.isAlreadyMaxPlanActive = true
            } else {
              this.isAlreadyMaxPlanActive = false
            }
          }
          for (let index = 0; index < this.planDetailArray.length; index++) {
            if ((this.planDetailArray[index].maxStorage > this.currentPlanArray.currentPlanStorage) && this.planDetailArray[index].maxUser > this.currentPlanArray.currentPlanMaxUsersCapacity) {
              this.planDetailArray[index].isPopupVisible = false
            } else {
              this.planDetailArray[index].isPopupVisible = true
            }
          }
          // } else {
          //   if (this.planDetailArray.length > 0) {
          //     let compareToArray = null
          //     for (let index = 0; index < this.planDetailArray.length; index++) {
          //       if (this.planDetailArray[index].maxUser < this.currentPlanArray.currentPlanMaxUsersCapacity) {
          //         console.log('Yeah that is Small users', this.planDetailArray[index])
          //         compareToArray = this.planDetailArray[index]
          //       }
          //     }
          //     if (compareToArray !== null) {
          //       this.displayErrorPopup = 
          //       `<span>You have selected </span>`
          //     }
          //   }
          // }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1032) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
      if (window.innerWidth < 640) {
        this.isSmallScreen = true
      } else{
        this.isSmallScreen = false
      }
    },
  },
};
</script>
<style scoped>


.cu2_switch {
  position: relative;
  display: inline-block;
  align-items: center;
  width: 60px;
  height: 30px;
}

.cu2_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cu2_slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 2px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.cu2_slider:before {
  position: absolute;
  content: "";
  height: 30px;
  top: 0px;
  width: 30px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  transition: .4s;
  box-shadow: 0px 1px 8px 3px #e0e0e0;
}

input:checked+.cu2_slider {
  background-color: #1296ba85;
}

input:checked+.cu2_slider:before {
  background-color: #1295BA;
  box-shadow: 2px 2px 4px 1px #c7c7c7;
}

input:focus+.cu2_slider {
  box-shadow: 0 0 1px #0c64a9;
}

input:checked+.cu2_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(36px);
}

/* Rounded sliders */
.cu2_slider.cu2_round {
  border-radius: 34px;
}

.cu2_slider.cu2_round:before {
  border-radius: 50%;
}

.csdcdc{
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}
.cu3_switch {
  position: relative;
  display: inline-block;
  align-items: center;
  width: 40px;
  height: 16px;
}

.cu3_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cu3_slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 2px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.cu3_slider:before {
  position: absolute;
  content: "";
  height: 22px;
  top: -3px;
  width: 22px;
  left: -5px;
  bottom: 0px;
  background-color: white;
  transition: .4s;
  box-shadow: 0px 1px 8px 3px #e0e0e0;
}

input:checked+.cu3_slider {
  background-color: #1296ba85;
}

input:checked+.cu3_slider:before {
  background-color: #1295BA;
  box-shadow: 2px 2px 4px 1px #c7c7c7;
}

input:focus+.cu3_slider {
  box-shadow: 0 0 1px #0c64a9;
}

input:checked+.cu3_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.cu3_slider.cu3_round {
  border-radius: 34px;
}

.cu3_slider.cu3_round:before {
  border-radius: 50%;
}
.circleLoader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #1295BA;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  animation: spin 1.2s linear infinite;
}
</style>